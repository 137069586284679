:root {
    --booking-color: #45505D;
}

.bg-booking-dark {
    background-color: var(--booking-color) !important;
}

.text-booking-dark {
    color: var(--booking-color) !important;
}

.or-overlay {
    margin-top: -10px;
}

.booking-border-color {
    border-bottom: 1px solid var(--booking-color) !important;
}

.booking-logo-image {
    width: 100%;
    height: 100%;
    /*object-fit: cover;*/
}

.booking-logo-image-cover {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.booking-logo-top {
    height: 70px;
}

.booking-logo-bottom {
    position: absolute;
    bottom: 8px;
    right: 16px;
}

.booking-details-element-header {
    padding: 0.5rem 0.5rem;
    cursor: pointer;
}

.booking-details-icon-closed:before {
    transform: rotate(90deg) !important;
    content: "\F536";
    padding: 5px 0px 0px 0px !important; 
    margin: 0px !important;

}

.booking-details-icon-open:before {
    transform: rotate(60deg) !important;
    content: "\F536";
    padding: 0px !important;
    margin: 0px !important;
}

.booking-btn-main {
    background-color: #2C0DE5 !important;
    border-radius: 4px;
}

.booking-btn-cancel {
    border-radius: 4px;
}

ul.booking-guidelines {
    list-style-type: none;
}

ul.booking-guidelines > li:before {
    content: "-";
}

.booking-btn-timeslot {
   width: 120px;
   border-radius: 4px;
}

.booking-disable-form {
    pointer-events: none;
    opacity: 0.7;
}

.booking-logo-bottom-size {
    width: 290px;
    height: 33.45px;
}